body {
    /* background-color: #1F1F1F; */
    background-color: #000000;
    font-family: "Roboto";
}

/* SWITCH */

.e-switch-wrapper.custom-switch {
  width: 50px;
  height: 24px;
}

.e-switch-wrapper.custom-switch .e-switch-handle {
  width: 20px;
  height: 16px;
}

.e-switch-wrapper.custom-switch .e-switch-inner,
.e-switch-wrapper.custom-switch .e-switch-handle {
  border-radius: 0;
}

.e-switch-wrapper.custom-switch .e-switch-handle.e-switch-active {
  left: 42px;
}

#container {
  visibility: hidden;
  margin-left: 10px;
}

.switch-control div {
  display: flex;
  align-items: center;
}

.switch-control h3 {
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif";
  text-indent: 23px;
  /* font-size: 6px; */
}

.switch-control {
  margin: 90px auto;
  width: 240px;
}

.switch-control label {
  -moz-user-select: none;
  cursor: pointer;
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif";
  font-size: 13px;
}

#loader {
  color: #008cff;
  height: 40px;
  width: 30%;
  position: absolute;
  top: 45%;
  left: 45%;
}


.e-switch-wrapper.handle-text {
  width: 58px;
  height: 24px;
}

.e-switch-wrapper.handle-text .e-switch-handle {
  width: 26px;
  height: 20px;
  left: 2px;
  background-color: #fff;
}

.e-switch-wrapper.handle-text .e-switch-inner,
.e-switch-wrapper.handle-text .e-switch-handle {
  border-radius: 0;
}

.e-switch-wrapper.handle-text .e-switch-handle.e-switch-active {
  left: 46px;
}

.e-switch-wrapper.handle-text .e-switch-inner.e-switch-active,
.e-switch-wrapper.handle-text:hover .e-switch-inner.e-switch-active .e-switch-on {
  background-color: #4d841d;
  border-color: #4d841d;
}

.e-switch-wrapper.handle-text .e-switch-inner,
.e-switch-wrapper.handle-text .e-switch-off {
  background-color: #e3165b;
  border-color: #e3165b;
}

.e-switch-wrapper.handle-text .e-switch-inner:after,
.e-switch-wrapper.handle-text .e-switch-inner:before {
  font-size: 10px;
  position: absolute;
  line-height: 21px;
  font-family: "Helvetica", sans-serif;
  z-index: 1;
  height: 100%;
  transition: all 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.e-switch-wrapper.handle-text .e-switch-inner:before {
  content: "OFF";
  color: #e3165b;
  left: 3px;
}

.e-switch-wrapper.handle-text .e-switch-inner:after {
  content: "ON";
  right: 5px;
  color: #fff;
}

.e-switch-wrapper.handle-text .e-switch-inner.e-switch-active:before {
  color: #fff;
}

.e-switch-wrapper.handle-text .e-switch-inner.e-switch-active:after {
  color: #4d841d;
}

/* SERVICES */

.services {
  border-bottom: solid 1px rgb(192, 192, 192);
  padding-bottom: 60px;
}

.services-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 2% 10px 2%;
  /* box-shadow: 7px 10px 10px rgba(93, 93, 93, 0.397);
  border: solid 1px rgb(224, 224, 224);
  border-radius: 10px; */
  /* align-items: center; */
}

.services-wrapper td {
  align-items: flex-start;
}

.services-wrapper table {
  margin: 0 10%;
}

.services-wrapper h2{
  font-size: 27px;
  font-weight: 400;
  padding: 0 2%;
}

.services-wrapper p {
  color:rgb(112, 112, 112);
  font-size: 18px;
  line-height: 30px;
  padding: 0 15%;
}

.aaaaaa span{
  margin: 0 0 2000px 0;
}

/* CONTACT */

.contact-card {
  padding: 2%;
  margin: 0 5% 5% 5%;
  border-radius: 20px;
  border: solid 1px rgba(33, 149, 243, 0.253);
  box-shadow: 20px 20px 50px rgba(114, 114, 114, 0.315)
}

.contact-us {
  width: fit-content;
  border-bottom: solid 1px rgb(33, 150, 243);
  /* color: white; */
  text-align: left;
  padding: 0 17% 1% 1%;
  margin: 0 0 6% 0;
  font-weight:100;
}


/* .button-send {
  text-align: right;
} */

/* PARTICLES */


.particles {
  position: absolute;
  top: 0;
  left: -100px; 
  width: 100%;
  height: 50%;
  z-index: -1;
}

.Top-home {
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo {
    margin: 17vh 0 0 0;
    width: 90%;
    max-width: 800px;
    margin-bottom: 110px;
}

.business {
    color:#C5B358;
    /* font-weight: 300; */
    /* color: #424242 */
}

.solutions {
    width: 100%;
    box-shadow: inset 0 7px 60px -20px rgba(0,0,0,0.4);
    background-color: rgb(240, 240, 240);
    margin-top: 10px
}


